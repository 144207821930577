$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #1c354f;
$coloryellow: #FFD15C;
$colorpink: #01869C;
$bertblue: #001933;
$bertdarkblue: #03264a;
$colorblue: #5D9EE3;
$colordefault: #454360;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}