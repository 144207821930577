/*=================================================================*/
/*                      SECTIONS                             
/*=================================================================*/
main.content {
	margin-left: 290px;
	@include transition(.3s);

	&.open {
		opacity: .8;
		-webkit-transform: translateX(290px);
		-moz-transform: translateX(290px);
		-ms-transform: translateX(290px);
		-o-transform: translateX(290px);
		transform: translateX(290px);
	}
}

main.content-2 {
	margin-left: 110px;
	@include transition(.3s);

	&.open {
		opacity: .8;
		-webkit-transform: translateX(110px);
		-moz-transform: translateX(110px);
		-ms-transform: translateX(110px);
		-o-transform: translateX(110px);
		transform: translateX(110px);
	}
}

main.content-3 {}

section {
	padding-top: 110px;
	position: relative;
}

.st0 {
    fill: #ffffff;
}
.st-yellow {
    fill: $coloryellow;
}

.st-blue {
    fill: $bertblue;
}

section.home {
    background-image: url(../images/o-r.png), radial-gradient(circle, #0e2b4a 0%, #05457d 100%);
	padding: 0;
	height: 100vh;
	min-height: 100vh;

	.intro {
		margin: auto;
		max-width: 540px;
		text-align: center;
		position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .profile-img {
            width: 260px;
            height: 260px;
            border-radius: 50%;
            border: 3px solid #fff;
            background: url(../images/bertguy.jpg) -21px -11px;
            background-size: 110% 110%;
            margin: 0 0 20px;
        }

		h1 {
			color: #FFF;
			font-size: 36px;
		}

		p {
			color: #FFF;
			font-size: 18px;
			margin-bottom: 0;
		}

		span {
			display: inline-block;

			label {
				margin-bottom: 0;

				&::after {
					content: attr(value);
				}
			}
		}
	}

	.social-icons {
		li {
			a {
				color: #FFF;
			}
		}
	}

	&.light {
		background: #F9F9FF;

		.intro {
			h1 {
				color: $colordark;
			}

			span,
			p {
				color: $colordefault;
			}
		}

		.social-icons {
			li {
				a {
					color: $colordark;

					&:hover {
						color: $coloryellow;
					}
				}
			}
		}
	}
}

.section-title {
	font-size: 36px;
	margin: 0;
	margin-left: 14px;
	position: relative;

	&:before {
		content: '';
		background-image: url('/images/dots-bg.svg');
		display: block;
		height: 37px;
		left: -14px;
		top: -14px;
		position: absolute;
		width: 37px;
	}
}

/*=================================================================*/
/*                      PARALLAX SHAPES                              
/*=================================================================*/
.parallax {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.layer {
		position: absolute;
	}

	.p1 {
		left: 10%;
		top: 10%;
	}

	.p2 {
		left: 25%;
		top: 30%;
	}

	.p3 {
		left: 15%;
		bottom: 30%;
	}

	.p4 {
		left: 10%;
		bottom: 10%;
	}

	.p5 {
		left: 45%;
		top: 10%;
	}

	.p6 {
		left: 40%;
		bottom: 10%;
	}

	.p7 {
		top: 20%;
		right: 30%;
	}

	.p8 {
		right: 30%;
		bottom: 20%;
	}

	.p9 {
		right: 10%;
		top: 10%;
	}

	.p10 {
		top: 45%;
		right: 20%;
	}

	.p11 {
		bottom: 10%;
		right: 10%;
	}
}

/*=================================================================*/
/*                      SKILLS                              
/*=================================================================*/
.skill-item {
	.skill-info {
		h4 {
			font-size: 16px;
			font-weight: 500;
		}

		span {
			font-size: 14px;
		}
	}
}

/*=================================================================*/
/*                      SERVICE                              
/*=================================================================*/
.service-box {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	@include transition(.3s);

	h3 {
		font-size: 20px;
	}

	img {
        margin-bottom: 22px;
        max-width: 95px;
	}

	&:hover {
		-webkit-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}

/*=================================================================*/
/*                      PORTFOLIO                              
/*=================================================================*/
.portfolio-wrapper {
	[class*="col-"] {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.pf-filter-wrapper {
	display: none;
}

.portfolio-item {
	position: relative;
	overflow: hidden;

	.thumb {
		overflow: hidden;
	}

	.details {
		color: #FFF;
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 1;

		h4 {
			color: #FFF;
			font-size: 20px;
			margin: 0 0 10px;
			padding: 0 20px;
			opacity: 0;
			transform: translateY(30px);
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
			position: absolute;
			top: 60px;
		}

		span.term {
			color: #FFF;
			background: $colorpink;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			font-size: 14px;
			opacity: 0;
			display: inline-block;
			padding: 3px 10px;
			position: absolute;
			top: 0;
			left: 20px;
			transform: translateY(-40px);
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		}

		.more-button {
			color: #FFF;
			font-size: 20px;
			display: block;
			background: $coloryellow;
			border-radius: 100%;
			height: 40px;
			line-height: 42px;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: 40px;
			opacity: 0;
			@include transition(.3s);
		}
	}

	.mask {
		background: $colorblue;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		height: 100%;
		width: 100%;
		@include transition(.3s);
	}

	&:hover {
		.mask {
			opacity: .9;
		}

		.details {

			h4,
			span {
				opacity: 1;
				transform: translateY(0);
			}

			.more-button {
				opacity: 1;
			}
		}
	}
}

.portfolio-filter {
	margin-bottom: 25px;

	li {

		button {
			color: #5E5C7F;
			cursor: pointer;
			font-family: $fonthead;
			font-size: 16px;
			font-weight: 700;
			position: relative;
			@include transition(.3s);
			height: auto;
			padding: 0;
			border: 0;
			outline: 0;
			background: transparent;

			&:hover,
			&.current {
				color: $colorpink;
			}
		}
	}

	li:not(:last-child) {
		margin-right: 1.8rem;
	}
}

select.portfolio-filter-mobile {
	font-size: 16px;
	border-radius: 10px;
	outline: 0;
	margin-bottom: 30px;
	position: relative;
	width: 100%;
}

.pf-filter-wrapper {
	position: relative;

	&:after {
		content: '\f0d7';
		font-family: 'FontAwesome';
		position: absolute;
		top: 0;
		right: 20px;
		color: #000;
	}
}

/*=================================================================*/
/*                      FACTS                              
/*=================================================================*/
.fact-item {
	.details {
		margin-left: 60px;
	}

	.icon {
		font-size: 36px;
		color: #DEDEEA;
		float: left;
	}

	.number {
		font-size: 30px;

		em {
			font-style: normal;
		}
	}

	p {
		font-size: 16px;
	}
}

/*=================================================================*/
/*                      PRICES                              
/*=================================================================*/
.price-item {
	padding: 30px 40px;

	h2.plan {
		font-size: 24px;
	}

	.price {
		font-size: 36px;
		font-weight: 700;
		margin: 0;

		em {
			font-style: normal;
			font-size: 14px;
			vertical-align: super;
			margin-right: 5px;
		}

		span {
			font-size: 16px;
			font-weight: 400;
			margin-left: 10px;
		}
	}

	ul {
		margin-bottom: 0;

		li {
			line-height: 2.1;
		}
	}

	.btn {
		margin-top: 20px;
	}

	&.best {
		padding: 55px 40px;
		z-index: 1;
		position: relative;
		margin-left: -20px;
		margin-right: -20px;
	}

	.badge {
		color: #FFF;
		background: $colorblue;
		transform: rotate(-90deg);
		position: absolute;
		left: 0;
		top: 46px;
		padding: 7px 12px;
		font-size: 14px;
		font-weight: 400;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

/*=================================================================*/
/*                      TESTIMONIALS                              
/*=================================================================*/
.testimonials-wrapper {
	max-width: 700px;
	margin: auto;
}

.testimonial-item {
	padding: 0 20px 20px;

	.thumb {
		border-radius: 50%;
		overflow: hidden;
		height: 90px;
		width: 90px;
	}

	.subtitle {
		color: #8AD7EF;
		font-size: 14px;
	}

	p {
		font-size: 16px;
	}
}

/*=================================================================*/
/*                      BLOG                              
/*=================================================================*/
.blog-item {
	overflow: hidden;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	@include transition(.3s);

	&:hover {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(-10px);
	}

	.thumb {
		position: relative;
		overflow: hidden;

		img {
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			@include transition(.3s);
		}

		&:hover {
			img {
				-webkit-transform: scale(1.1);
				-ms-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}

	.category {
		background: $colorpink;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		color: #FFF;
		font-size: 14px;
		padding: 2px 8px;
		position: absolute;
		left: 20px;
		top: 0;
		z-index: 1;
	}

	.details {
		padding: 20px;

		h4.title {
			font-size: 20px;

			a {
				color: $colordark;

				&:hover {
					color: $colorpink;
				}
			}
		}
	}

	.meta {
		font-size: 14px;
		color: #8B88B1;

		li:not(:last-child) {
			margin-right: 0.6rem;
		}

		li {
			&:after {
				content: '';
				background: #8B88B1;
				border-radius: 50%;
				;
				display: inline-block;
				height: 3px;
				margin-left: 0.6rem;
				vertical-align: middle;
				width: 3px;
			}

			&:last-child::after {
				display: none;
			}
		}
	}
}

.blog-page-section {
	background-color: #f9f9ff;
	padding-top: 0;

	.blog-img-text {
		padding: 30px;
		box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, .1);
	}

	.blog-text-title {
		h4 {
			font-size: 30px;

			a {
				color: #454360;

				&:hover {
					color: #FF4C60;
				}
			}
		}
	}

	.blog-pagination {
		margin: 60px 0px 40px;

		ul {
			li {
				height: 50px;
				width: 50px;
				line-height: 50px;
				margin: 0px 10px;
				border-radius: 100%;
				display: inline-block;
				box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);

				a {
					width: 100%;
					display: block;
					font-weight: 500;
					border-radius: 100%;

					&:hover {
						background-color: $colorpink;
					}
				}
			}
		}
	}

	.blog-standard {}

	.blog-standard .meta {
		font-size: 14px;
		color: #8b88b1;
	}

	.blog-standard .meta li:after {
		content: "";
		background: #8b88b1;
		border-radius: 50%;
		display: inline-block;
		height: 3px;
		margin-left: .6rem;
		vertical-align: middle;
		width: 3px;

	}

	.blog-standard .meta li {
		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.blog-standard .meta li:not(:last-child) {
		margin-right: .6rem;
	}

	.blog-standard .meta li a {
		color: #353353;

		&:hover {
			color: #ff4c60;
		}
	}

	.br-more {
		a {
			border-radius: 60px;
			font-family: "Rubik", sans-serif;
			font-size: 16px;
			font-weight: 700;
			overflow: hidden;
			line-height: 1;
			padding: 12px 32px;
			color: #fff;
			background: #ff4c60;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			-webkit-transform: perspective(1px) translateZ(0);
			transform: perspective(1px) translateZ(0);
			box-shadow: 0 0 1px rgba(0, 0, 0, 0);

			&:hover {
				color: #fff;
				-webkit-animation-name: button-push;
				animation-name: button-push;
				-webkit-animation-duration: .3s;
				animation-duration: .3s;
				-webkit-animation-timing-function: linear;
				animation-timing-function: linear;
				-webkit-animation-iteration-count: 1;
				animation-iteration-count: 1;
			}
		}
	}

	.widget {
		margin-bottom: 30px;
		padding: 20px;
	}

	.screen-reader-text {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute !important;
		width: 1px;
		word-wrap: normal !important;
	}

	.widget .searchform input[type=text] {
		border-radius: 30px;
		border: 0;
		background: #f3f3f3;
		padding: 6px 20px;
		max-width: 190px;
	}

	.widget .searchform input[type=submit] {
		color: #fff;
		background: #ff4c60;
		border-radius: 25px;
		padding: 5px 20px;
		border: 0;
		cursor: pointer;
	}

	.shadow-dark {
		box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, .1);
	}

	.widget-header {
		margin-bottom: 15px;
		margin-top: 0;
	}

	.widget ul {
		padding-left: 1.1rem;
	}

	.blog-single {
		background: #fff;
		padding: 30px;
		border-radius: 20px;

		img {
			width: 100%;
		}
	}

	.comment-respond {
		margin-top: 2.5rem;
		border-top: solid 1px #eee;
		clear: both;
	}

	.comment-respond .comment-notes {
		font-style: italic;
	}

	.comment-respond label {
		display: block;
		margin-bottom: .5rem;
	}

	.comment-respond textarea,
	.comment-respond input[type=text],
	.comment-respond input[type=email],
	.comment-respond input[type=url] {
		border-radius: 30px;
		border: 0;
		background: #f3f3f3;
		padding: 6px 20px;
		outline: 0;
		max-width: 100%;
	}

	.comment-respond textarea {
		height: 10rem;
	}

	.comment-form-cookies-consent {
		align-items: baseline;
		display: flex;
	}

	.comment-respond input[type=checkbox]+label {
		font-size: 14px;
		margin-left: 10px;
	}

	.comment-respond input[type=submit] {
		border-radius: 60px;
		font-family: "Rubik", sans-serif;
		font-size: 16px;
		font-weight: 700;
		overflow: hidden;
		line-height: 1;
		padding: 12px 32px;
		color: #fff;
		background: #ff4c60;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		border: none;
		cursor: pointer;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);

		&:focus {
			outline: none;
		}

		&:hover {
			color: #fff;
			-webkit-animation-name: button-push;
			animation-name: button-push;
			-webkit-animation-duration: .3s;
			animation-duration: .3s;
			-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
			-webkit-animation-iteration-count: 1;
			animation-iteration-count: 1;
		}
	}

	.comment-area-section {
		padding-bottom: 10px;

		h3 {
			margin: 0;
			padding-bottom: 30px;
		}

		.comment-inner-box {
			margin-bottom: 40px;

			&.reply-comment {
				margin-left: 50px;
			}

			.comment-author-img {
				width: 90px;
				height: 90px;
				overflow: hidden;
				border-radius: 100%;
				margin-right: 20px;
			}

			.comment-author-text {
				overflow: hidden;

				h4 {
					margin: 0;
					padding-bottom: 5px;
				}

				span {
					font-size: 14px;
					color: #6d6d6d;
				}

				p {
					margin-bottom: 0;
					padding-bottom: 10px;
				}

				.reply-btn {
					a {
						color: #fff;
						border-radius: 20px;
						padding: 0px 10px;
						display: inline-block;
						background-color: #ff4c60;
					}
				}
			}
		}
	}

}

/*=================================================================*/
/*                      CLIENTS                              
/*=================================================================*/
.client-item {
	min-height: 50px;
	position: relative;
	text-align: center;
	margin-top: 60px;

	.inner {
		text-align: center;
		position: absolute;
		left: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 100%;
	}

	img {
		display: inline-block;
	}
}

/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
	background-image: url('/images/map.svg');
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 200px;

	h3 {
		font-size: 23px;
		margin: 0 0 10px;
	}
}

/*=================================================================*/
/*                      HELPER                              
/*=================================================================*/
.help-block.with-errors {
	color: red;
	font-size: 12px;
	padding-left: 30px;

	ul {
		margin-bottom: 0;
	}
}

.spacer {
	clear: both;


	&[data-height='60'] {
		height: 60px;
	}

	&[data-height='70'] {
		height: 70px;
	}

	&[data-height='30'] {
		height: 30px;
	}

	&[data-height='96'] {
		height: 96px;
	}

	&[data-height='20'] {
		height: 20px;
	}
}

.text-link {
	font-family: $fonthead;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}

#infscr-loading {
	position: absolute;
	left: 50%;
	margin-left: -15px;
	bottom: 10px;
}

#infscr-loading img {
	display: none;
}

.fa-spinner {
	margin-right: 10px;
	display: none;
}

.scroll-down {
	position: absolute;
	bottom: 40px;
	left: 0;
	width: 100%;
	z-index: 1;

	button {
		background: transparent;
		outline: 0;
		border: 0;
		cursor: pointer;
	}

	&.light {
		span {
			color: $colordefault;
		}

		.mouse {
			border-color: $colordark;

			.wheel {
				background: $colordark;
			}
		}
	}
}

@-webkit-keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

@-moz-keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

@keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

.mouse-wrapper {
	color: #FFF;
	font-size: 14px;
	display: block;
	max-width: 100px;
	margin: auto;
	text-align: center;
	cursor: pointer;

	span {
		color: #ffffff;
	}

	&:hover {
		color: #FFF;
	}
}

.mouse {
	border: solid 2px #FFF;
	border-radius: 16px;
	display: block;
	margin: auto;
	margin-top: 10px;
	height: 26px;
	position: relative;
	width: 20px;

	.wheel {
		background: #FFF;
		border-radius: 100%;
		display: block;
		position: absolute;
		top: 8px;
		left: 50%;
		transform: translateX(-50%);
		height: 4px;
		width: 4px;
		-webkit-animation: ani-mouse 2s linear infinite;
		-moz-animation: ani-mouse 2s linear infinite;
		animation: ani-mouse 2s linear infinite;
	}
}

.circle {
	border-radius: 100%;
}

footer.footer {
	background: $colordark;
	padding: 40px 0;
	text-align: center;

	.copyright {
		color: #9aabb3;
		font-size: 14px;
	}

	&.light {
		background: #F9F9FF;
		border-top: solid 1px rgba(0, 0, 0, 0.05);
	}
}

.triangle-top-sm {
    &:before {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #FFF;
        position: absolute;
        left: 10%;
        top: -10px;
        transform: translateX(-7.5px)
    }
}
body.dark {
    .triangle-top-sm {
        &:before {
            border-bottom-color: #1c354f;
        }
    }
}

@media (max-width: 600px) {
    .parallax, .parallax .layer {
        display: none;
    }
    .timeline {
        margin-bottom: 20px;
    }
    section.home {
        .intro { 
            .profile-img {
            width: 230px;
            height: 230px;
            }
        }
    }
}